@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}

html {
  scroll-behavior: auto;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-display: swap;
}

body {
  min-height: 100vh !important;
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

/* Third party library styles */

.gm-style-iw-chr {
  display: none;
}

.gm-style .gm-style-iw-c {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #000 !important;
  height: 2px;
}

.css-ttwr4n {
  background-color: #000 !important;
}

.css-heg063-MuiTabs-flexContainer {
  justify-content: center;
}

.styles_poll__1QbHf .styles_answers__17597 .styles_option__1sn1C,
.styles_poll__1QbHf .styles_answers__17597 .styles_result__SJJfE {
  border-radius: 30px !important;
  /* border-color: #CCDFFF !important; */
}

.styles_poll__1QbHf,
.styles_answers__17597,
.styles_result__SJJfE {
  border-radius: 30px !important;
  /* border-color: #CCDFFF !important; */
}

.styles_poll__1QbHf
  .styles_answers__17597
  .styles_result__SJJfE
  .styles_fill__6jfwi {
  border-radius: 30px !important;
  /* color: #CCDFFF !important; */
}

.styles_fill__6jfwi {
  background-color: antiquewhited !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0;
}

.css-heg063-MuiTabs-flexContainer {
  justify-content: left;
}

/*Scrollable chips */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-height: auto !important;
}

.arrowStyles {
  position: 'absolute';
  top: 'calc(50% + 56px)';
  width: 20;
  height: 20;
  cursor: 'pointer';
}

.swiper-pagination {
  position: relative !important;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  padding-top: 32px;
}

.css-1ujnqem-MuiTabs-root {
  align-items: center !important;
}

.css-1a1fmpi-MuiInputBase-root-MuiInput-root:after {
  border-bottom-style: none !important;
}

ul {
  margin: 0;
}

.css-1q2h7u5 {
  min-height: '28px !important';
  min-width: '30px !important';
}

.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.Mui-focused fieldset {
  border-color: 'black !important';
}

.pac-container {
  z-index: 90000;
}

.css-463in9-JoyTextarea-root {
  --Textarea-focusedHighlight: #096bde !important;
}

#footer_id {
  position: fixed;
  bottom: 0;
}

.swal2-styled.swal2-cancel {
  background-color: white !important;
  color: #000 !important;
}

.background-url-love-register {
  background: linear-gradient(
      160.41deg,
      rgba(0, 0, 0, 0.8) 19.23%,
      rgba(255, 13, 129, 0.8) 205.15%
    ),
    url('../public/assets/pikrepo.png');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-url-love-take-quiz {
  background: linear-gradient(
      161.92deg,
      rgba(0, 0, 0, 0.8) 24.49%,
      rgba(13, 104, 255, 0.52) 205.43%
    ),
    url('../public/assets/love_take_quiz.png');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.linear-gradient-for-blurred-image {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -32.69%,
    rgba(0, 0, 0, 0.88) 57.7%
  );

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gray-linear-gradient {
  background: linear-gradient(
    332deg,
    rgba(57, 57, 57, 0.8) 62.23%,
    rgba(45, 45, 45, 0.8) 100%
  );
}

.css-ahj2mt-MuiTypography-root {
  font-size: 14px;
}

.generic-quiz-bg {
  background: linear-gradient(
      160.41deg,
      rgba(0, 0, 0, 0.8) 19.23%,
      rgba(253, 253, 253, 0.8) 205.15%
    ),
    url('../public/assets/Urban_quiz_image.png');
}

.psycho-quiz-bg {
  background: linear-gradient(
      160.41deg,
      rgba(0, 0, 0, 0.8) 19.23%,
      rgba(253, 253, 253, 0.8) 205.15%
    ),
    url('../public/assets/psychometric_quiz_image.png');
}

.MuiSlider-root {
  color: black !important;
}

.MuiSlider-valueLabel {
  color: black !important;
  padding: 0px !important;
  top: 0 !important;
  background: none !important;
}

.Mui-selected {
  color: black;
}

.swal2-actions {
  justify-content: flex-end !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 16px !important;
}

.swal2-html-container {
    margin: 16px !important;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.title-style {
  padding: 0 !important;
}

a:hover {
  /* color: black !important; */
  cursor: pointer;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.close-button-swal {
  color: black !important;
  outline: none !important;
  font-size: 2em !important;
  padding-right: 10px !important;
  padding-top: 20px !important;
  font-weight: lighter !important;
}

.close-button-swal:hover {
  color: black !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0;
}

.swal-toast-container {
  z-index: 999999999 !important;
}

.city-writer-bg {
  background: url('../public/assets/city-writer-bg.svg');
}

.css-zjq1i3 {
  margin: auto;
  height: auto;
  max-height: 100vh;
  max-width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.css-w6xjhe {
  padding: 0 20px;
  text-align: center;
  border-radius: 100px;
  /* border: 1px solid white; */
  font-size: 24px;
  flex-shrink: 0;
  margin-left: 1em;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  color: white;
  background-color: #5c5c5c;
}

@media only screen and (max-width: 768px) {
  .tws-post-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.ql-container {
  height: 300px !important;
}

.rdp-day {
  margin: 2px !important;
  color: var(--text-high);
}

.rdp-day_selected {
  opacity: 1 !important;
  background-color: #000 !important;
  color: white !important;
}

p > a {
  color: var(--text-high);
}

.ql-blank {
  background-color: #f9f9f9;
}

.highlight-bg {
  background: linear-gradient(
    177.01deg,
    #e7e7e7 24.29%,
    rgba(231, 231, 231, 0) 170.61%
  );
}

.upgrade_profile_bg_url {
  background: url('../public/assets/upgrade_my_profile_bg.svg');
}

.rdw-container {
  width: 100%;
  max-width: 37.5rem;
  margin: 0 auto;
}

#tamaro-widget.tamaro-widget {
  padding: 2rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
}

.floating-card-detail > ul {
  list-style: disc !important;
  margin: 0 10px !important;
  padding: 0 10px !important;
}

.floating-card-detail > ol {
  list-style: decimal !important;
  margin: 0 10px !important;
  padding: 0 10px !important;
}

.rich-text-editor {
  word-break: break-word;
}

.rich-text-editor ul {
  list-style: disc !important;
  margin: 0 10px !important;
  padding: 0 10px !important;
}

.rich-text-editor ol {
  list-style: decimal !important;
  margin: 0 10px !important;
  padding: 0 10px !important;
}

.rich-text-editor a {
  color: var(--text-high) !important;
  text-decoration: underline !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.rdp {
  --rdp-cell-size: 28px;
  /* Size of the day cells. */
  --rdp-caption-font-size: 14px;
  /* Font size for the caption labels. */
  --rdp-accent-color: #0000ff;
  /* Accent color for the background of selected days. */
  --rdp-background-color: #e7edff;
  /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #3003e1;
  /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270;
  /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff;
  /* Color of selected day text */
}

.rdp-caption {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  color: #666666 !important;
}

.rdp-head_row {
  color: #b6bdc2 !important;
}

.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_middle {
  background: #e7e7e7 !important;
  color: black !important;
}

.css-nk9sok-MuiButtonBase-root-MuiTab-root {
  font-size: 16px !important;
  font-weight: 700;
}

.css-1d58gqj-MuiTypography-root {
  font-size: 14px !important;
}

.css-j204z7-MuiFormControlLabel-root {
  margin-right: 8px !important;
}

.market-ad-bg {
  background: linear-gradient(
    2deg,
    rgba(57, 57, 57, 0.89) 18.71%,
    rgba(39, 39, 39, 0.62) 82.18%,
    rgba(0, 0, 0, 0) 98.31%
  );
}

.ad-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 22.4px */
  letter-spacing: 0.07px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.swal2-container {
  z-index: 99999 !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

/* Dashboard Swiper */
#dashboard .swiper-container {
  overflow: visible;
  padding-left: 25%;
  padding-right: 25%;
}

#dashboard .swiper-slide-active {
  transform: scale(1.1) !important;
  z-index: 2;
}

#dashboard .swiper-slide-prev,
#dashboard .swiper-slide-next {
  transform: scale(0.85) !important;
  opacity: 0.8;
  z-index: 1;
}

#dashboard .mySwiper {
  padding-left: 20% !important;
  padding-right: 20% !important;
}

@media only screen and (max-width: 768px) {
  #dashboard .mySwiper {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
}

.loader {
  animation: loader 0.7s infinite linear;
}

@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}

.smooth-loader {
  aspect-ratio: 1;
  border-radius: 50%;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.swal2-styled.swal2-confirm {
  border-radius: 100px !important;
}

.rich-text-details p {
  margin: 16px 0 !important;
}

