/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Font color */
  --text-high: #212121;
  --text-mid: #666666;
  --text-low: #9e9e9e;
  --text-disable: #b1b2b3;
  --text-white: #ffffff;
  /* Background color */
  --primary: #1a171b;
  --primary-400: #333333;
  --primary-300: #545454;
  --primary-200: #8a8a8a;
  --primary-100: #b0b0b0;
  --primary-50: #e6e6e6;
  --light-grey: #f6f6f6;
  /* Border color */
  --border-light: #f6f6f6;
  --border-mid: #ebebeb;
  --border-dark: #9d9d9d;
  /* Indicator color */
  --indicator-green: #00c853;
  --indicator-red: #eb5757;
  /* Font size */
  --heading-1: 60px;
  --heading-2: 48px;
  --heading-3: 36px;
  --heading-4: 28px;
  --heading-5: 22px;
  --subtitle-1: 18px;
  --subtitle-2: 16px;
  --body-1: 16px;
  --body-1-semibold: 16px;
  --body-1-bold: 16px;
  --body-2: 14px;
  --body-2-semibold: 14px;
  --body-2-bold: 14px;
  --caption: 12px;
  --caption-bold: 12px;
  --overline: 12px;
  --extra-small-caption: 10px;
}

.btn-black {
  @apply flex items-center justify-center rounded-full border-[1px] border-black bg-black px-[16px] py-[8px] text-[14px] font-bold text-white;
}

.btn-outlined {
  @apply flex items-center justify-center rounded-[100px] border-[1px] border-mid px-[16px] py-[8px] text-[14px] font-bold text-[#0F0F0F];
}

.btn-outlined-disabled {
  @apply flex items-center justify-center rounded-full border-[1px] border-black px-[16px] py-[8px] text-[14px] font-bold text-black opacity-20;
}

.responsive-width-margin {
  @apply mx-auto w-full max-w-[1440px] px-[16px] md:px-[32px] lg:px-[48px] xxl:px-[64px];
}

.custom-container {
  @apply mx-auto w-full max-w-[1440px] px-[16px] md:px-[32px] lg:px-[48px] xxl:px-[64px];
}

.custom-scrollbar {
  @apply scrollbar-thin scrollbar-track-light-grey scrollbar-thumb-[#B1B2B3] scrollbar-track-rounded-md scrollbar-thumb-rounded-full;
}

.posts-grid {
  @apply flex flex-wrap justify-center md:justify-start gap-4;
}

.ad-btn {
  @apply h-[36px] rounded-[8px] px-[28px] text-[15px] font-bold;
}

.form-container {
  @apply px-4 md:px-8 py-6 md:py-9;
}

.author-name {
  @apply max-w-[160px] cursor-pointer truncate font-semibold text-high leading-[22.4px] tracking-[0.07px]
}

.market-card-icon {
  @apply text-[12px] text-high
}

.post-title {
  @apply cursor-pointer text-ellipsis break-words font-semibold text-high
}

.post-description {
  @apply break-words text-[16px] leading-[22.4px] tracking-[0.07px] text-mid
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-clamp: 6;
}

.line-clamp-7 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  line-clamp: 7;
}

.line-clamp-8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  line-clamp: 8;
}

.line-clamp-9 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  line-clamp: 9;
}

.line-clamp-10 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  line-clamp: 10;
}

.line-clamp-11 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 11;
  line-clamp: 11;
}

.form-footer {
  @apply p-6 md:px-[48px] md:py-[30px] flex cursor-pointer items-center justify-end
}

.line-clamp-inherit {
  -webkit-line-clamp: inherit;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}